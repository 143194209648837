export default {

    toggleNavMenu()
    {
        var element = document.getElementById('main-nav');
        element.classList.toggle("open");

        var element = document.getElementById('mob-nav-fade');
        element.classList.toggle("open");
    },

    toggleCommentBox()
    {
        var element = document.getElementById('testimonial-add-box');
        element.classList.toggle("open");

        var element = document.getElementById('testimonial-add-box-fade');
        element.classList.toggle("open");
    }

}
